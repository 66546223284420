.Projects {
    max-width: 1920px;
    padding: 1.5rem;
    margin: 0 auto;
}

@media screen and (max-width: 767px) {
    .grid {
        height: auto !important;
    }

}

@media screen and (min-width: 768px) {
    .grid {
        display: flex;
        flex-flow: column wrap;
        align-content: space-between;

        &::before,
        &::after {
            content: "";
            flex-basis: 100%;
            width: 0;
            order: 2;
        }
    }
}

.item {
    gap: 24px;
    margin-bottom: 24px;
    position: relative;
}

@media screen and (min-width: 768px) {
    .col2 {
        width: calc(50% - calc(36px / 2));

        &:nth-child(2n+1) {
            order: 1;
        }

        &:nth-child(2n) {
            order: 2;
        }
    }

    .col3 {
        width: calc(33.3334% - calc(72px / 3));

        &:nth-child(3n+1) {
            order: 1;
        }

        &:nth-child(3n+2) {
            order: 2;
        }

        &:nth-child(3n) {
            order: 3;
        }
    }
}