.Showreel {
    height: auto;
    padding-top: 4rem;
    position: relative;
    display: none;
}

@media screen and (min-width: 768px) {
    .Showreel {
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

@media screen and (min-width: 1200px) {
    .Showreel {
        padding-top: 0;
        height: 85%;
    }
}

.layout {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 auto;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
}

.video {
    width: 100%;
    /* height: 100%; */
    padding-top: 75%;
    position: relative;
    background: hsl(128 100% 1% / 1);

    overflow: hidden;
}

.thumb {
    cursor: pointer;
}

.end {
    height: 100%;
    width: 100%;
    max-width: 720px;
    padding: 0 2rem;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5vh;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.comingSoon {
    width: 100%;
    height: auto;
}

.replay {
    width: clamp(5rem, 10vw, 8rem);
    height: auto;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.replay:hover {
    filter: brightness(2);
    transform: scale(1.15);
}

.backdrop {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10rem;
    z-index: 1;

    /* background: hsl(128 100% 1% / 0.8);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px); */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, hsl(128 100% 1% / 1) 70%);
}

@media screen and (max-width: 1199px) {
    .backdrop {
        display: none;
    }
}