.Project {
    position: relative;
    display: block;
}

@media screen and (min-width: 768px) {
    .Project {
        height: 520px;
    }
}

.video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image {
    position: relative;
    width: 100%;
    object-fit: cover;
    min-height: calc(100vw - 3rem);
}

@media screen and (min-width: 768px) {
    .image {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        min-height: unset;
        object-fit: cover
    }
}